// CampaignForm.js
import React, { useState } from "react";
import axios from "axios";

const CampaignForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [formPage, setFormPage] = useState(true);
  const [formError, setFormError] = useState(false);

  const handleChange = (e) => {
    setFormError(false);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const callApi = (formData) => {
    const data = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      mobile_number: formData.mobile,
      email_id: formData.email,
    };
    axios
      .post(
        "https://stage.api.twicefun.eclipsemints.com.au/insert-record",
        data
      )
      .then((res) => {
        console.log(res);
        setFormPage(false);
      })
      .catch((err) => {
        console.log(err);
        setFormPage(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.mobile
    ) {
      callApi(formData);
    } else {
      setFormError(true);
    }
  };

  return (
    <>
      {formPage && (
        <form onSubmit={handleSubmit} className="form">
          <div className="input-field">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="input"
            />
          </div>
          <div className="input-field">
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className="input"
            />
          </div>
          <div className="input-field">
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="input"
            />
          </div>
          <div className="input-field">
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Mobile"
              className="input"
            />
          </div>
          {formError && (
            <div className="error-msg">Please provide all the details</div>
          )}
          <div className="submit-container">
            <button className="submit-btn" type="submit">
              Submit
            </button>
          </div>
        </form>
      )}
      {!formPage && <h1>Form Submitted Successfully</h1>}
    </>
  );
};

export default CampaignForm;
