import CampaignForm from "./Form";
const Landing = () => {
  return (
    <>
      <div id="Header"></div>
      <div className="content">
        <CampaignForm />
      </div>
      <div id="Footer"></div>
    </>
  );
};

export default Landing;
